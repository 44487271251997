import { BsTelephoneFill } from 'react-icons/bs';
import { MdEmail, MdLocationPin } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../../CSS/Footer.css';
import '../../CSS/MobileApp/M_Footer.css';

// import markerSDK from '@marker.io/browser';
const Footer = () => {
  const mobileScreen = useSelector((state) => state.isMobile.value);
  function startEmail() {
    window.open(`mailto:info@nationalpublicseating.com?subject=Contact Us`);
  }
  const navigate = useNavigate();
  return (
    <footer>
      <div className='icons-bar'>
        <img
          src='https://res.cloudinary.com/da3rom333/image/upload/e_trim:10/v1688653587/Website%20Assets/SVGs/new_footer_vvmzae.svg'
          alt='Footer Banner'
        />
      </div>
      <div className='footer-background'>
        <div className='footer-div'>
          <div
            role='button'
            aria-label='Go to National Public Seating Home Page'
            tabIndex={0}
            className='footer-logo'
            onClick={() => {
              navigate('/');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate('/');
              }
            }}
            style={{ cursor: 'pointer' }}
          ></div>

          <div className='footer-links'>
            <ul>
              <li className='footer-link'>
                <button
                  aria-label='Go to National Public Seating Home Page'
                  onClick={() => {
                    navigate(`/`);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      navigate(`/`);
                    }
                  }}
                >
                  {' '}
                  Home
                </button>
              </li>
              <li className='footer-link'>
                <button
                  aria-label='Go to About Page'
                  onClick={() => {
                    navigate(`/about`);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      navigate(`/about`);
                    }
                  }}
                >
                  {' '}
                  About
                </button>
              </li>
              <li className='footer-link'>
                <button
                  aria-label='Go to Catalogs & Brochures'
                  onClick={() => {
                    navigate(`/media-center`);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      navigate(`/media-center`);
                    }
                  }}
                >
                  {' '}
                  Catalogs &amp; Brochures
                </button>
              </li>
              <li className='footer-link'>
                <button
                  aria-label='Go to Videos'
                  onClick={() => {
                    navigate(`/videos`);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      navigate(`/videos`);
                    }
                  }}
                >
                  Videos
                </button>
              </li>
              <li className='footer-link'>
                <button
                  aria-label='Go to Service Portal'
                  onClick={() => {
                    navigate(`/contact`);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      navigate(`/contact`);
                    }
                  }}
                >
                  Service Portal
                </button>
              </li>
              <li className='footer-link'>
                <button
                  aria-label='Go to Dealer Locator'
                  onClick={() => {
                    navigate(`/dealer-locator`);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      navigate(`/dealer-locator`);
                    }
                  }}
                >
                  Dealer Locator
                </button>
              </li>
              <li className='footer-link'>
                <button
                  aria-label='Go to Terms & Conditions'
                  onClick={() => {
                    navigate(`/about/terms`);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      navigate(`/about/terms`);
                    }
                  }}
                >
                  Terms &amp; Conditions
                </button>
              </li>
            </ul>
          </div>
          <div className='footer-quick-links'>
            <h2>Quick Links</h2>
            <li className='footer-quick-link'>
              <button
                aria-label='Go to Dealer Portal'
                onClick={() => {
                  navigate(`/Dealer-Portal`);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    navigate(`/Dealer-Portal`);
                  }
                }}
              >
                Dealer Portal
              </button>
            </li>
            <li className='footer-quick-link'>
              <button
                aria-label='Go to Media Center'
                onClick={() => {
                  navigate(`/Media-Center`);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    navigate(`/Media-Center`);
                  }
                }}
              >
                Media Center
              </button>
            </li>
            <li className='footer-quick-link'>
              <button
                aria-label='Go to Freight Quote'
                onClick={() => {
                  navigate(`/cart`);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    navigate(`/cart`);
                  }
                }}
              >
                Freight Quote
              </button>
            </li>
            <li className='footer-quick-link'>
              <button
                aria-label='Go to Service Portal'
                onClick={() => {
                  navigate(`/contact`);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    navigate(`/contact`);
                  }
                }}
              >
                Service Portal
              </button>
            </li>
            <li className='footer-quick-link'>
              <button
                aria-label='Go to New'
                onClick={() => {
                  navigate(`/whats-new`);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    navigate(`/whats-new`);
                  }
                }}
              >
                News
              </button>
            </li>
          </div>
          <div className='footer-contact-us'>
            <h2
              role='button'
              aria-label='Contact Us'
              tabIndex={0}
              onClick={startEmail}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  startEmail();
                }
              }}
            >
              CONTACT US!
            </h2>
            <div className='contact-div contact-phone'>
              <BsTelephoneFill className='phoneSvg' />{' '}
              <a
                aria-label='Contact Us via Phone'
                href={`tel:800-261-4112`}
              >
                800.261.4112
              </a>
            </div>
            <div className='contact-div contact-location'>
              <MdEmail className='locationSvg' />
              <p
                role='link'
                aria-label='Contact Us via Email'
                tabIndex={0}
                onClick={startEmail}
                className='NPSLocation'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    startEmail();
                  }
                }}
              >
                contact@nationalpublicseating.com
              </p>
            </div>
            <div className='contact-div contact-location'>
              <MdLocationPin className='locationSvg' />
              <p
                role='link'
                aria-label='Address'
                tabIndex={0}
                onClick={() => {
                  window.open('https://maps.google.com?q=149 Entin Road Clifton, NJ 07014');
                }}
                className='NPSLocation'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    window.open('https://maps.google.com?q=149 Entin Road Clifton, NJ 07014');
                  }
                }}
              >
                149 Entin Road
                {mobileScreen.isMobile ?
                  <span>|</span>
                : <br></br>}
                Clifton, NJ 07014
              </p>
            </div>

            {/* <button
              className="submitFeedback"
              onClick={() => {
                navigate("/contact?category=general-feedback");
              }}
            >
              Submit Feedback
            </button> */}
          </div>
        </div>

        <div className='footer-copyright'>
          <div className='footer-underline'></div>© Copyright 2023 National Public Seating
          {mobileScreen.isMobile ?
            <br></br>
          : <span> | </span>}
          Designed by The National Public Seating Development Team
        </div>
      </div>
    </footer>
  );
};

export default Footer;
